import "./bootstrap";
import "../css/app.css";
import "../css/admin/admin.css";
import "../css/admin/admin-tailwind.css";
import "../css/print/print.scss";

import Alpine from "alpinejs";

window.Alpine = Alpine;

Alpine.start();
